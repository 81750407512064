// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import MainServices from 'components/promptService/mainServices';
import WaitingTime from 'components/promptService/waitingTime';
import OutDoor from 'components/units/unitPages/unit/outdoorService';
import HealthInsurance from 'components/HealthInsurance';
import { HeaderContext } from 'context/header';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: 30px;
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  /* :nth-child(3) {
    @media ${device.laptop} {
      padding-top: 1.875rem;
      border-top: 0.063rem solid #c6c8cc;
    }
  } */

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
  }
`;

const PartHealthInsurance = styled.div`
  grid-column: ${props => props.gridColumn};
  padding: ${props => props.padding};

  @media ${device.laptop} {
    padding: ${props => props.paddingLaptop};
    ${props =>
      props.borderBottomLaptop &&
      css`
        border-bottom: 1px solid #c6c8cc;
      `}

    ${props =>
      props.marginLaptop &&
      css`
        margin: ${props.marginLaptop};
      `}
  }
`;

const HR = styled.hr`
  width: 100%;
  margin: 0;
  border: 0 solid #c6c8cc;

  @media ${device.laptop} {
    display: none;
  }
`;

const GridMainServices = styled.div`
  display: grid;
  margin: 0;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 1.875rem;
  background: #f4f5f7;
  padding-bottom: 4.8125rem;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding: 1.25rem;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
    background: #f4f5f7;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const RenderPage = (page, isDesktop) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'}></Part>
        <Part gridColumn={'2 / -2'}>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckjizkieg12o20a72o5i1bdls', true)}
            prontoAtendimento
            padding="4.4375rem 0"
            notTitle
            componentOpen
            outcomponent
            dropdownMarginBottom
          />
        </Part>
        <GridMainServices>
          <Part gridColumn={'2 / -2'} id="tempo-de-espera">
            <WaitingTime isPA isBackground={false} />
          </Part>
        </GridMainServices>
        <Part gridColumn={'2 / -2'}>
          <MainServices
            background="#fff"
            markdown={getMarkdown(page, 'ckjj0e9e014hj0a72cqrgsgae', true)}
          />
        </Part>

        <PartHealthInsurance
          gridColumn={'1 / -1'}
          padding="0"
          paddingLaptop="1.25rem 0"
          marginLaptop="0 -1.25rem"
        >
          <HealthInsurance defaultUF="SP" />
        </PartHealthInsurance>
        <Part gridColumn={'2 / -2'}>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckjj0mgww11ql0a73tk33p0en', true)}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
          />
        </Part>
        <Part gridColumn={'2 / -2'}>
          <OutDoor
            pageName="Bela Vista"
            url="/unidades/bela-vista/detalhes"
            Outdor
            isProntoAtendimento
          />
        </Part>
      </GridContainer>
    </>
  );
};

const EmergencyRoom = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  return (
    <Layout>
      <SEO dataSeo={page} />
      {RenderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckjiyawfc0ypj0b182sgadrsv" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  activeBreaknews
                  breaknews {
                    id
                    name
                    type
                    singletexts
                    markdown
                  }
                  newnessBannerActive
                  newnessBanner {
                    id
                    name
                    type
                    markdown
                  }

                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  map {
                    id
                    place
                    zoom
                    assetpicker {
                      handle
                      width
                      height
                      url
                    }
                    coordenadas {
                      latitude
                      longitude
                    }
                    desktop
                    mobile
                  }
                  fragments {
                    id
                    name
                    type
                    markdown
                    singletexts
                  }
                }
                fragments {
                  id
                  name
                  markdown
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <EmergencyRoom page={response.gcms.site.pages} />;
      }}
    />
  );
};
